body {
    background: #F8F8F8;
}

ion-modal {
    --width: 80%;
    --height: 80%;
}

.pac-container {
    z-index: 1300!important;
}